@use "src/shared/styles/common/layout.module.scss";

.container {
	@include layout.paper-color;

	display: flex;
  align-items: start;
  cursor: pointer;

  &:hover {
    background-color: var(--color-custom-main-pale);
  }
}

.id {
  display: inline-block;
  width: 8rem;
  margin-right: 1rem;
}

.name {
  display: inline-block;
  width: 25rem;
  margin-right: 1rem;
}