.container {
	padding: 0 1rem 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: var(--color-primary-main);
    cursor: pointer;
    border-bottom: 1px solid var(--color-primary-main);
  }
}

.inner {
	display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .9rem;
  height: 30px; // Match the height of the row in react window to align the text vertically at center.

  & > svg {
    margin-left: .5rem;
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--color-primary-main);
  }
}

.company {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 1rem;
}