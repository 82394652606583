@use 'src/shared/styles/common/layout.module.scss';

.container {
	display: flex;
  align-items: center;
  gap: 1.2rem;
}

.userName {
  font-size: 1.2rem;
  line-height: 1.4rem;

	cursor: pointer
}

.menu {
	@include layout.card;

	position: fixed;
  top: 6rem;
  right: 1rem;
}