@use 'src/shared/styles/common/layout.module.scss';
@use 'src/shared/styles/common/typography.module.scss';

.form {
	height: 70vh;
	overflow: auto;
	padding: 1rem;

	h2 {
		margin-bottom: 1.5rem;
	}
}

.row {
	@include layout.grid-row;

	margin-bottom: 1rem;
  justify-content: stretch;
}

.paper {
	@include layout.paper-color;
}

.column {
  flex: 1;
}

.buttons {
	@include layout.grid-row;

	align-items: center;

	&__inner {
		@include layout.grid-row;
	}
}

.caption {
	@include typography.caption;
}

.searchBar {
	margin-bottom: 1rem;
}