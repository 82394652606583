.form {
	display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 1rem;
  }
}

.preloader {
	display: flex;
  justify-content: center;

  width: 100%;
}