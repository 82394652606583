.inputContainer {
	display: flex;
	position: relative;

  & > div {
    flex-grow: 1;
  }

  & input {
    background-color: var(--color-custom-app-background);
    border-radius: .4rem;
    box-shadow: var(--shadow-1);
  }

  // MUI modifications
  & [class~=MuiOutlinedInput-root] {
    &:hover fieldset {
      border-color: var(--color-primary-main);
    }

    &[class~=Mui-focused]fieldset {
      border-color: var(--color-primary-main);
      border-width: 1px;
    }
	}
}

.linearProgress {
	position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.textField {
	& [class~=MuiInputBase-inputMarginDense] {
    padding: 1rem 1.5rem;
  }
}

.button {
	margin-left: 1rem;
}

.searchResults {
	position: relative;
  z-index: 100;

  padding-bottom: 1.2rem;

  height: fit-content;
  width: 100%;

  background-color: var(--color-custom-app-background);

  box-shadow: var(--shadow-2);
}

.fixedSizeList {
	margin-bottom: 1rem;
}