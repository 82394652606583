@use "src/shared/styles/common/layout.module.scss";

.container {
	@include layout.container;

	padding: 2rem;
}

.users{
	@include layout.paper-color;

	overflow-x: auto;

	display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  gap: 1rem;

  width: 100%;
	height: 70vh;
}

.button {
	margin-bottom: 1rem;
	align-self: start;
}