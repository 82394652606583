@use 'src/shared/styles/common/layout.module.scss';

.container {
	display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.inner {
	@include layout.card;

	padding: 2rem;
}

.logo {
	margin-bottom: 2rem;

  & img {
    width: 200px;
  }
}