.button {
	padding: .4rem 1rem;
	border-radius: 30px;
}

.inner {
	display: flex;
	gap: .5rem;
	align-items: center;
}
