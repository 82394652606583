@use "src/shared/styles/common/layout.module.scss";

.title {
	margin-bottom: 1rem;
}

.list {
	height: 35rem;
  overflow-y: auto;

  & > li:not(:last-child) {
    margin-bottom: .5rem;
  }

	&__item {
		@include layout.paper-color;
	
		& span {
			width: 90%;
		}
	}
}

.remove {
	width: 1rem;
  height: 1rem;

  cursor: pointer;

	fill: var(--color-primary-main);
}