.container {
	position: sticky;
  top: 0;
  z-index: 500;

  display: flex;
  justify-content: space-between;
  padding: .8rem 2rem;

  background-color: var(--color-custom-app-background);

  box-shadow: var(--shadow-2);
}

.left {
	display: flex;
	align-items: center;

	& > *:not(:last-child) {
		margin-right: 4rem;
	}
}

.logo {
	& img {
    width: 10rem;
    object-fit: contain;
  }

  & :hover {
    cursor: pointer;
  }
}